import { setError, setLoading, setSession } from '@/store/auth/slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { supabase } from '@/supabase';
import { APP_ROUTES } from '@/ui/application-routes';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * useAuthSetup hook fetches the session and sets the session state in the store. It also handles the auth state change and redirects the user to the appropriate route based on the auth state.
 */
export const useAuthSetup = () => {
  const session = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleFetchSession = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (!session) {
      if (!pathname.startsWith(APP_ROUTES.AUTH.MAIN))
        navigate(APP_ROUTES.AUTH.MAIN, { replace: true });
    }

    dispatch(setSession(data.session));
    dispatch(setError(error));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    handleFetchSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session && !session.user.email_confirmed_at) {
        if (!pathname.startsWith(APP_ROUTES.AUTH.VERIFY_EMAIL))
          navigate(APP_ROUTES.AUTH.VERIFY_EMAIL, { replace: true });
        return;
      }
      dispatch(setSession(session));
      switch (_event) {
        case 'SIGNED_IN':
          if (
            !session?.user?.email_confirmed_at &&
            !pathname.startsWith(APP_ROUTES.AUTH.VERIFY_EMAIL)
          )
            navigate(APP_ROUTES.AUTH.VERIFY_EMAIL, { replace: true });
          if (
            session?.user?.email_confirmed_at &&
            pathname.startsWith(APP_ROUTES.AUTH.MAIN)
          )
            navigate(APP_ROUTES.DASHBOARD, { replace: true });
          break;
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [pathname]);

  return { session };
};
