import { Button } from '../../primitives/button';

type FullPageErrorProps = {
  customTitle?: string;
  customMessage?: string;
  customAction?: React.ReactNode;
};

export const FullPageError = ({
  customTitle,
  customMessage,
  customAction,
}: FullPageErrorProps) => {
  return (
    <div className='flex min-h-[100vh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8'>
      <div className='mx-auto text-center'>
        <div className='mx-auto h-12 w-12 text-primary' />
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl'>
          {customTitle ?? 'Oops, something went wrong!'}
        </h1>
        <p className='mt-4 text-muted-foreground max-w-md mx-auto'>
          {customMessage ??
            'We&apos;re sorry, but an unexpected error has occurred. Please try again later or contact support if the issue persists.'}
        </p>
        <div className='mt-8 space-x-4'>
          <Button
            className='mx-auto'
            variant={customAction ? 'secondary' : 'default'}
          >
            Contact support
          </Button>
          {customAction}
        </div>
      </div>
    </div>
  );
};
