import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Session } from '@supabase/supabase-js';

export type AuthState = {
  session: Session | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: AuthState = {
  session: null,
  isLoading: true,
  error: null,
};

export const authSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session | null>): void => {
      state.session = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>): void => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<Error | null>): void => {
      state.error = action.payload;
    },
  },
});

export const { setSession, setLoading, setError } = authSlice.actions;
export const authReducer = authSlice.reducer;
