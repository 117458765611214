import PiloteerLogo from '@/assets/logo.svg?react';

import { Spinner } from './spinner';

export const FullPageLoading = () => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='flex flex-col items-center space-y-4'>
        <PiloteerLogo className='w-16 h-16' />
        <Spinner className='w-8 h-8' />
      </div>
    </div>
  );
};
