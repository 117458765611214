import { cn } from '@/utils/tailwind';

type SpinnerProps = {
  className?: string;
};

export const Spinner = ({ className }: SpinnerProps) => {
  return (
    <div
      className={cn(
        'inline-block w-8 h-8 border-2 border-solid border-gray-300 border-t-black rounded-full animate-spin dark:border-gray-500 dark:border-t-white',
        className
      )}
    />
  );
};
