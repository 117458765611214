import { TranscriptSegmentRaw, WordRaw } from '@/types/transcript';
import camelcaseKeys from 'camelcase-keys';

export const sanitizeWordFromRaw = (word: WordRaw) => {
  return {
    ...camelcaseKeys(word, { deep: true }),
  };
};

export const sanitizeTranscriptSegmentFromRaw = (
  transcriptSegment: TranscriptSegmentRaw
) => {
  return {
    ...camelcaseKeys(transcriptSegment, { deep: true }),
  };
};

export const sanitizeTranscriptFromRaw = (
  transcript: TranscriptSegmentRaw[]
) => {
  return transcript.map((transcriptSegment) =>
    sanitizeTranscriptSegmentFromRaw(transcriptSegment)
  );
};
