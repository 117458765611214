import { CheckoutSessionRaw, PortalSessionRaw } from '@/types/transaction';
import camelcaseKeys from 'camelcase-keys';

export const sanitizeCheckoutSessionFromRaw = (
  checkoutSession: CheckoutSessionRaw
) => {
  return {
    ...camelcaseKeys(checkoutSession, { deep: true }),
  };
};

export const sanitizePortalSessionFromRaw = (
  portalSession: PortalSessionRaw
) => {
  return {
    ...camelcaseKeys(portalSession, { deep: true }),
  };
};
