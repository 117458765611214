import { useAppSelector } from '@/store/hooks';
import { APP_ROUTES } from '@/ui/application-routes';
import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type EnforceAuthProps = {
  children: React.ReactNode;
};

export const EnforceAuth: FunctionComponent<EnforceAuthProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { session, isLoading, error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isLoading) return;
    if (error) {
      navigate(APP_ROUTES.AUTH.MAIN, { replace: true });
    }
    if (!session) {
      navigate(APP_ROUTES.AUTH.MAIN, { replace: true });
    }
  }, [session, isLoading, error, navigate]);

  return <>{children}</>;
};
