import {
  DomainReportRaw,
  MeetingDimensionalReportRaw,
  MeetingDimensionalReportsAggregatedRaw,
  MeetingLeadershipResonanceAggregatedRaw,
  MeetingRaw,
  RecentMeetingRaw,
  SelectedExcerptRaw,
} from '@/types/meeting';
import camelcaseKeys from 'camelcase-keys';

export const sanitizeMeetingFromRaw = (meeting: MeetingRaw) => {
  return {
    ...camelcaseKeys(meeting, { deep: true }),
  };
};

export const sanitizeSelectedExcerptFromRaw = (excerpt: SelectedExcerptRaw) => {
  return {
    ...camelcaseKeys(excerpt, { deep: true }),
  };
};

export const sanitizeMeetingLeadershipResonanceAggregatedFromRaw = (
  resonances: MeetingLeadershipResonanceAggregatedRaw
) => {
  return {
    companions: resonances.companions.map((companion) => ({
      ...camelcaseKeys(companion),
    })),
  };
};

export const sanitizeMeetingDimensionalReportsAggregatedFromRaw = (
  reports: MeetingDimensionalReportsAggregatedRaw
) => {
  return {
    ...camelcaseKeys(reports, { deep: true }),
  };
};
export const sanitizeDomainReportFromRaw = (report: DomainReportRaw) => {
  return {
    ...camelcaseKeys(report, { deep: true }),
  };
};

export const sanitizeDimensionalReportsFromRaw = (
  reports: MeetingDimensionalReportRaw
) => {
  return {
    ...camelcaseKeys(reports, { deep: true }),
  };
};

export const sanitizeRecentMeetingFromRaw = (meeting: RecentMeetingRaw) => {
  return {
    ...camelcaseKeys(meeting, { deep: true }),
  };
};
