import { ZoomIntegrationRaw } from '@/types/integration';
import {
  Meeting,
  MeetingDimensionalReportsAggregated,
  MeetingDimensionalReportsAggregatedRaw,
  MeetingLeadershipResonanceAggregated,
  MeetingLeadershipResonanceAggregatedRaw,
  MeetingRaw,
  RecentMeeting,
  RecentMeetingRaw,
} from '@/types/meeting';
import { Objective, ObjectiveRaw } from '@/types/objective';
import { CheckoutSession, CheckoutSessionRaw } from '@/types/transaction';
import { Transcript, TranscriptRaw } from '@/types/transcript';
import { BillingPeriod, User, UserRaw } from '@/types/user';
import {
  sanitizeMeetingDimensionalReportsAggregatedFromRaw,
  sanitizeMeetingFromRaw,
  sanitizeMeetingLeadershipResonanceAggregatedFromRaw,
  sanitizeRecentMeetingFromRaw,
} from '@/utils/sanitizers/meeting';
import { sanitizeObjectiveFromRaw } from '@/utils/sanitizers/objective';
import { sanitizeCheckoutSessionFromRaw } from '@/utils/sanitizers/transaction';
import { sanitizeTranscriptFromRaw } from '@/utils/sanitizers/transcript';
import { sanitizeUserFromRaw } from '@/utils/sanitizers/user';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithIntercept } from './base';

export const piloteerApi = createApi({
  reducerPath: 'piloteerApi',
  baseQuery: baseQueryWithIntercept,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    check: builder.query<void, void>({
      query: () => 'check',
    }),

    // User endpoints
    getUser: builder.query<User, void>({
      query: () => 'users',
      transformResponse: (response: UserRaw) => sanitizeUserFromRaw(response),
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<User, Partial<UserRaw>>({
      query: (user) => ({
        url: 'users',
        method: 'PATCH',
        body: user,
      }),
      transformResponse: (response: UserRaw) => sanitizeUserFromRaw(response),
      invalidatesTags: ['User'],
    }),
    updateProfilePicture: builder.mutation<User, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: '/users/profile-picture',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      transformResponse: (response: UserRaw) => sanitizeUserFromRaw(response),
      invalidatesTags: ['User'],
    }),

    // Meeting endpoints
    getAllMeetings: builder.query<Meeting[], void>({
      query: () => 'meetings',
      transformResponse: (response: MeetingRaw[]) =>
        response.map((meeting) => sanitizeMeetingFromRaw(meeting)),
    }),
    getRecentMeetings: builder.query<RecentMeeting[], void>({
      query: () => 'meetings/recent',
      transformResponse: (response: RecentMeetingRaw[]) =>
        response.map((meeting) => sanitizeRecentMeetingFromRaw(meeting)),
    }),
    getMeetingByUuid: builder.query<Meeting, string>({
      query: (uuid) => `meetings/${uuid}`,
      transformResponse: (response: MeetingRaw) =>
        sanitizeMeetingFromRaw(response),
    }),
    getMeetingTranscript: builder.query<Transcript, string>({
      query: (uuid) => `meetings/${uuid}/transcript`,
      transformResponse: (response: TranscriptRaw) =>
        sanitizeTranscriptFromRaw(response),
    }),

    invite: builder.mutation<Meeting, string>({
      query: (meetingLink) => ({
        url: 'meetings/invite',
        method: 'POST',
        body: { meeting_url: meetingLink },
      }),
      transformResponse: (response: MeetingRaw) =>
        sanitizeMeetingFromRaw(response),
    }),
    getLeadershipResonancesAggregated: builder.query<
      MeetingLeadershipResonanceAggregated,
      void
    >({
      query: () => `meetings/leadership-resonances/aggregate`,
      transformResponse: (response: MeetingLeadershipResonanceAggregatedRaw) =>
        sanitizeMeetingLeadershipResonanceAggregatedFromRaw(response),
    }),
    getDimensionalReportsAggregated: builder.query<
      MeetingDimensionalReportsAggregated,
      void
    >({
      query: () => `meetings/dimensional-reports/aggregate`,
      transformResponse: (response: MeetingDimensionalReportsAggregatedRaw) =>
        sanitizeMeetingDimensionalReportsAggregatedFromRaw(response),
    }),

    // Objective endpoints
    getLatestObjectives: builder.query<Objective[], void>({
      query: () => 'objectives/latest',
      transformResponse: (response: ObjectiveRaw[]) =>
        response.map((objective) => sanitizeObjectiveFromRaw(objective)),
    }),

    // Subscription endpoints
    createSubscriptionCheckout: builder.mutation<
      CheckoutSession,
      { plan: BillingPeriod }
    >({
      query: ({ plan }) => ({
        url: 'transactions/subscriptions/create-checkout-session',
        method: 'POST',
        body: { plan },
      }),
      transformResponse: (response: CheckoutSessionRaw) =>
        sanitizeCheckoutSessionFromRaw(response),
    }),
    createCreditPurchaseCheckout: builder.mutation<
      CheckoutSession,
      { quantity: number }
    >({
      query: ({ quantity }) => ({
        url: 'transactions/subscriptions/create-credit-purchase-session',
        method: 'POST',
        body: { quantity },
      }),
      transformResponse: (response: CheckoutSessionRaw) =>
        sanitizeCheckoutSessionFromRaw(response),
    }),

    // Integration endpoints
    connectZoomAccount: builder.mutation<void, ZoomIntegrationRaw>({
      query: (zoomIntegration) => ({
        url: 'integrations/zoom/connect',
        method: 'POST',
        body: zoomIntegration,
      }),
    }),

    disconnectZoomAccount: builder.mutation<void, void>({
      query: () => ({
        url: 'integrations/zoom/disconnect/',
        method: 'POST',
      }),
    }),

    // getCheckoutSession: builder.query<CheckoutSession, string>({
    //   query: (sessionId) => `subscriptions/checkout/${sessionId}`,
    //   transformResponse: (response: CheckoutSessionRaw) =>
    //     sanitizeCheckoutSessionFromRaw(response),
    // }),
    // updateSubscription: builder.mutation<
    //   void,
    //   { plan: UserSubscriptionPlan; billingPeriod: UserBillingPeriod }
    // >({
    //   query: ({ plan, billingPeriod }) => ({
    //     url: 'subscriptions',
    //     method: 'PATCH',
    //     body: { plan, billing_period: billingPeriod },
    //   }),
    //   invalidatesTags: ['User'],
    // }),
    // cancelAllSubscriptions: builder.mutation<void, void>({
    //   query: () => ({
    //     url: 'subscriptions',
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['User'],
    // }),
    // getPortalSession: builder.query<PortalSession, void>({
    //   query: () => 'subscriptions/portal',
    //   transformResponse: (response: PortalSessionRaw) =>
    //     sanitizePortalSessionFromRaw(response),
    // }),
  }),
});

export const {
  useLazyCheckQuery,
  // User hooks
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useUpdateProfilePictureMutation,
  // Meeting hooks
  useGetAllMeetingsQuery,
  useGetRecentMeetingsQuery,
  useLazyGetMeetingByUuidQuery,
  useGetMeetingByUuidQuery,
  useGetMeetingTranscriptQuery,
  useInviteMutation,
  useGetLeadershipResonancesAggregatedQuery,
  useGetDimensionalReportsAggregatedQuery,
  //Objective hooks
  useGetLatestObjectivesQuery,
  // Subscription hooks
  useCreateSubscriptionCheckoutMutation,
  useCreateCreditPurchaseCheckoutMutation,
  // Integration hooks
  useConnectZoomAccountMutation,
  useDisconnectZoomAccountMutation,
} = piloteerApi;
